<script lang="ts" setup>
import { usePage } from '@inertiajs/vue3';
import { SelectField } from '@vue-interface/select-field';
import type { MgDataVariable } from 'types';
import { computed, watchEffect } from 'vue';

const page = usePage<{
    states: Record<string,string>
}>();

const props = defineProps<{
    id: string,
    name: string,
    item: MgDataVariable,
    index: number,
    errors: any,
    modelValue: string
}>();

const emit = defineEmits<{
    'update:modelValue': [value: string],
    'update:formattedValue': [value: string],
    'update:readableValue': [value: string]
}>();

const model = computed({
    get() {
        return props.modelValue;
    },
    set(value) {
        emit('update:modelValue', value);
    }
});

const isDefault = computed(() => {
    return model.value === props.item.defaultValue;
});

watchEffect(() => {
    if(isDefault.value) {
        return emit('update:formattedValue', props.item.defaultValue);
    }
    emit('update:formattedValue', `"${model.value}"`);

    emit('update:readableValue', page.props.states[model.value]);
});

</script>

<template>
    <div class="flex items-start gap-x-2">
        <SelectField
            :id="id"
            v-model="model"
            :name="name"
            :error="errors[name]"
            class="w-full">
            <option>
                {{ item.defaultValue }}
            </option>
            <option
                v-for="state in Object.keys(page.props.states)"
                :key="state"
                :value="state">
                {{ page.props.states[state] }}
            </option>
        </SelectField>
        <slot
            v-if="!isDefault"
            name="reset" />
    </div>
</template>